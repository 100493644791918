import {BlockParagraphTitle} from "./BlockParagraphTitle";
import "./BlockCancellationPolicy.scss";
import React from "react";

export function BlockCancellationPolicy({model, className = ""}) {

    if(!model.voucherdataarr.cancellationpolicy) {
        return <div className={`BlockCancellationPolicy ${className}`}></div>;
    }


    return (
        <div className={`BlockCancellationPolicy ${className}`}>
            <div className="cancellation-policy-title"><BlockParagraphTitle>Reservation and Cancellation
                Policy</BlockParagraphTitle></div>
            <div className="cancellation-policy-text">{model.voucherdataarr.cancellationpolicy}</div>
        </div>
    );
}
