import React from "react";

import "./BlockAttributes.scss";
import {RoundedBox} from "../RoundedBox";
import {PropertyTable} from "../PropertyTable";

export function BlockAttributes({model, className = ""}) {

    const attributes = model.attributes;
    const mappedAttributes = Object.entries(attributes).map(([attribute, value]) => {
        return {title: attribute, value}
    });

    return (
        <div className={`BlockAttributes ${className}`}>
            <RoundedBox>
                <PropertyTable data={mappedAttributes}></PropertyTable>
            </RoundedBox>
        </div>
    )
}