/**
 * Check if the model has valid coordinates
 *
 * Valid coordinates are defined as an array of objects with a 'from' and 'to' property or for a single coordinate, an object with a 'from' and 'to' property
 *
 *
 * A single transfer has just the 'from' and 'to' property
 *
 * when we have multiple "transportations", we have an array of objects with 'from' and 'to' property
 *
 *
 * @param model
 * @returns {boolean}
 */
export const hasValidCoordinates = (model) => {
    if(model.coordinates) {
        if(model.coordinates.length) {
            if(model.coordinates.length > 0) {
                if(model.coordinates[0].from && model.coordinates[0].to) {
                    //console.info('Valid coordinates in', model.template_id, model.coordinates, model);
                    return true;
                }
                //console.warn('Invalid coordinates in', model.template_id, model.coordinates, model);
            }
        }else{
            if(model.coordinates.from && model.coordinates.to) {
                if(model.coordinates.from.lat != 0 ) {
                    return true;
                }
            }
        }
    }
    return false;
};
