import {BlockParagraphTitle} from "./BlockParagraphTitle";
import {useApiData} from "../../hooks/useApiData";
import {BlockEditableText} from "./BlockEditableText";
import "./BlockCombo.scss";
import React from "react";

export function BlockCombo({model, className = ""}) {

    const {isProposal} = useApiData();

    if (!model.combos.length) {
        return <div className={`BlockCombo ${className}`}></div>;
    }

    return (
        <div className={`BlockCombo ${className}`}>
            <BlockParagraphTitle>Rooms:</BlockParagraphTitle>
            <ul>
                {model.combos.map((combo, index) => {
                    return (
                        <li key={index}>
                            {isProposal && combo.quantity > 1 && `${combo.quantity} x `}
                            {/* for itinerary */}
                            {!isProposal && `${combo.quantity} ${combo.unit}${combo.quantity > 1 ? 's': ''} `}
                            {/*<span className="editable">*/}
                            {/*    <span className="text single-line" data-placeholder="Combo Title" data-estimateline_id={combo.estimateline_id} data-name="title">{combo.label}</span>*/}
                            {/*</span>*/}

                            <BlockEditableText model={combo} attribute={'title'} />

                            {isProposal && <span className="proposal_price"> - {combo.price} {combo.unit ? ` per ${combo.unit}` : ''}</span>}
                        </li>
                    );
                })}
            </ul>
        </div>
    )
}
