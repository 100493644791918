import React from "react";
import "./PropertyTable.scss";
import {PropertyTableValue} from "./PropertyTableValue";

export function PropertyTable({data}) {
    return (
        <div className={"PropertyTable"}>
            {data.map((item, index) => (
                <div className={"property-row gap-1"} key={index}>
                    <div className={"property-title"}>{item.title}</div>
                    <PropertyTableValue value={item.value}></PropertyTableValue>
                </div>
            ))}
        </div>
    );
}
