import React, {useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player';

import './BlockVideo.scss';

export function BlockVideo({src, caption, playing = false, onPlay = () => {}, volume = 1, className = ""}) {
    // videoRef is used to store the reference to the video's wrapper element
    const videoRef = useRef(null);

    // isPlaying stores if the video is playing in a state
    const [isPlaying, setIsPlaying] = useState(playing);
    // isPopped determines if the video is popped
    const [isPopped, setIsPopped] = useState(false);
    // isUnpopping determines if the video is during the unpopping animation
    const [isUnpopping, setIsUnpopping] = useState(false);

    // This function will trigger the unpopping animation and then set the isPopped state to false
    const unpop = () => {
        setIsUnpopping(true);
        setTimeout(() => {
            setIsUnpopping(false);
            setIsPopped(false);
        }, 500) // This should match the animation duration in the CSS
    }

    useEffect(() => {
        // This observer will check if the video's wrapper is visible in the viewport
        // We use a wrapper because the video will be moved around the DOM when popped
        const observer = new IntersectionObserver(
            ([entry]) => {
                // If the video is not visible and it's playing, we should pop it
                if (!entry.isIntersecting && isPlaying) {
                    setIsPopped(true);
                }

                // If the video is not playing, or it's visible, we should unpop it
                if(!isPlaying || entry.isIntersecting) {
                    // If it's already popped, or it's unpopping don't do anything
                    if (!isPopped || isUnpopping)  {
                        return;
                    }
                    // If it's popped, trigger the unpopping
                    unpop();

                }
            },
            { threshold: 0.1 }
        );

        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, [isPlaying, isPopped, isUnpopping]);

    // Using a wrapper function to pass the arguments to the onPlay callback
    // and set the isPlaying state
    const onPlayCallback = () => {
        onPlay(arguments);
        setIsPlaying(true);
    }

    return (
        <div ref={videoRef} className={`BlockVideo ${className}`}>
            <div className={`blockvideo-wrapper ${isPopped ? 'pop' : ''} ${isUnpopping ? 'unpopping' : ''}`}>
                <ReactPlayer
                    url={src}
                    playing={playing}
                    volume={volume}
                    loop={true}
                    width="100%"
                    height="100%"
                    controls={false}
                    onPlay={onPlayCallback}
                    onPause={() => setIsPlaying(false)}
                    config={{
                        youtube: {
                            playerVars: {
                                showinfo: 1,
                                controls: 0,
                            }
                        },
                        vimeo: {
                            playerOptions: {
                                controls: true,
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
}