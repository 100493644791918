import React from "react";
import {BlockDescription, BlockRemarks, BlockRenderer, BlockTitle} from "../blocks/Blocks";

export const SelfBooked = ({model}) => {
    // Overriding the description with the original one
    model.itinerary_description = model.original_description;

    return (
        <div className={"SelfBooked"}>
            <BlockRenderer Component={BlockTitle} model={model}/>

            <BlockRenderer Component={BlockDescription} model={model}/>

            <BlockRenderer Component={BlockRemarks} model={model}/>
        </div>
    )
};
