import React from "react";
import {RoundedBox} from "../RoundedBox";
import {Icon} from "../Icon";
import {PropertyTable} from "../PropertyTable";
import "./VipAssistance.scss";
import {BlockDescription, BlockImages, BlockItineraryNotes, BlockPrice, BlockRemarks} from "../blocks/Blocks";

export const VipAssistance = ({model}) => {
    return (
        <div className={"VipAssistance"}>
            <RoundedBox>
                <div className={"vipassistance-title-container"}>
                    <div className={"vipassistance-title"} dangerouslySetInnerHTML={{__html: model.title}}/>
                    <div className={"vipassistance-icon"}>
                        <Icon name={"vipassistance"}></Icon>
                    </div>
                </div>
                <div className={"vipassistance-content"}>
                    <div className={"vipassistance-content-left"}>
                        <BlockDescription model={model}></BlockDescription>
                    </div>
                    <div className={"vipassistance-content-right"}>
                        <PropertyTable data={model.voucher}></PropertyTable>
                    </div>
                </div>

                <BlockImages model={model}/>

                <BlockItineraryNotes model={model}/>

                <BlockPrice model={model}/>

                <BlockRemarks model={model}/>

            </RoundedBox>
        </div>
    )
};
