import {Experience} from "./Experience";
import {Hotel} from "./Hotel";
import {Transfer} from "./Transfer";
import {Restaurant} from "./Restaurant";
import {Attraction} from "./Attraction";
import {BoatAsDirected} from "./BoatAsDirected";
import {Apartment} from "./Apartment";
import {GuidedTour} from "./GuidedTour";
import {Miscellaneous} from "./Miscellaneous";
import {CarAsDirected} from "./CarAsDirected";
import {ComboTransportation} from "./ComboTransportation";
import {Train} from "./Train";
import {BoatTransfer} from "./BoatTransfer";
import {VipAssistance} from "./VipAssistance";
import {BundleContent} from "./BundleContent";
import {DriverGuide} from "./DriverGuide";
import {Guide} from "./Guide";
import {Guides} from "./Guides";
import {JetsHelicopters} from "./JetsHelicopters";
import {SelfBooked} from "./SelfBooked";
import {Sport} from "./Sport";
import {Theatre} from "./Theatre";
import {Tour} from "./Tour";
import {Villa} from "./Villa";
import {Default} from "./Default";
import "./ComponentBlock.scss";
import {Flight} from "./Flight";
import {Ticket} from "./Ticket";
import {Coach} from "./Coach";

const components = {
    "apartment": Apartment,
    "asdirectedcar": CarAsDirected,
    "attraction": Attraction,
    "boat": BoatAsDirected,
    "boatasdirected": BoatAsDirected,
    "boattransfer": BoatTransfer,
    "carrental": BoatTransfer,
    "coach": Coach,
    "bundlecontent": BundleContent,
    "combotransportation": ComboTransportation,
    "default": Default,
    "driverguide": DriverGuide,
    "experience": Experience,
    "flight": Flight,
    "guide": Guide,
    "guidedtour": GuidedTour,
    "guides": Guides, // this is a list of guides
    "hotel": Hotel,
    "miscellaneous": Miscellaneous,
    "privatejet": JetsHelicopters,
    "restaurant": Restaurant,
    "selfbooked": SelfBooked,
    "sports": Sport,
    "tickets": Ticket,
    "theatre": Theatre,
    "touroperator": Tour,
    "train": Train,
    "transfer": Transfer,
    "villa": Villa,
    "vipassistance": VipAssistance,
}

export function ComponentBlock({item}) {
    const Component = components[item.template_id] || Miscellaneous;

    return (
        <div className={"ComponentBlock"}>
            <Component model={item}></Component>
        </div>
    );
}
