import React from "react";
import {
    BlockDescription,
    BlockImages,
    BlockItineraryNotes,
    BlockPrice,
    BlockRemarks,
    BlockTransportTitle
} from "../blocks/Blocks";
import {PropertyTable} from "../PropertyTable";
import {RoundedBox} from "../RoundedBox";

export const Flight = ({model}) => {
    return (
        <div className={"Flight"}>
            <RoundedBox>
                <BlockTransportTitle model={model}/>

                <PropertyTable data={model.voucher}></PropertyTable>

                <BlockDescription model={model}/>

                <BlockItineraryNotes model={model}/>

                <BlockPrice model={model}/>

                <BlockRemarks model={model}/>
            </RoundedBox>

        </div>
    )
};
