import {PropertyTable} from "../PropertyTable";
import React from "react";
import "./BlockTable.scss";

export function BlockTable({model, className = ""}) {

    return (
        <div className={`BlockTable ${className}`}>
            {model.voucher && <PropertyTable data={model.voucher}></PropertyTable>}
        </div>
    )
}