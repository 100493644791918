import React from 'react';
import LoremIpsum from "react-lorem-ipsum";
import {default as Qlogo} from '../logo.svg';
import scrollToElement from "../helpers/scrollToElement";
import {useApiData} from "../hooks/useApiData";

function Terms() {

    const {data, editable} = useApiData();

    return (
        <div id={"termsandconditions"} className={"Terms Rightblock Textblock colsize"}>

            <div>
                <div className={"remark title"}>Terms and Conditions</div>
                <div dangerouslySetInnerHTML={{__html:data.terms_and_conditions}}></div>
            </div>


            <div className={"foot"}>
            <div className={"assistance remark"}>For assistance leading up and during your stay, <br/>please contact
                    us on +41 916 900 010
                </div>
                <div className={"newrow "}>
                    <div className={"left"}>
                        <a href="#home" onClick={scrollToElement} ><img src={Qlogo}/></a>
                    </div>
                    <div className={"right align-items-end"}>
                        Have a nice holiday!
                    </div>
                </div>
            </div>


        </div>
    );
};

export default Terms;
