import React from "react";
import {
    BlockAlternativeText, BlockCombo,
    BlockDescription,
    BlockImages,
    BlockItineraryNotes,
    BlockPrice,
    BlockRemarks,
    BlockRenderer,
    BlockSubtitle,
    BlockTable,
    BlockTitle
} from "../blocks/Blocks";

export const Ticket = ({model}) => {
    return (
        <div className={"Ticket"}>
            <BlockRenderer Component={BlockTitle} model={model}/>

            <BlockRenderer Component={BlockAlternativeText} model={model}/>

            <BlockRenderer Component={BlockSubtitle} model={model}>
                {model.provider_label && <div dangerouslySetInnerHTML={{__html: model.provider_label}}/>}
                {model.address && <div>Address: <strong dangerouslySetInnerHTML={{__html: model.address}}/></div>}
                {model.voucherdataarr.bookingref && <div>Reservation number: <strong
                    dangerouslySetInnerHTML={{__html: model.voucherdataarr.bookingref}}/></div>}
            </BlockRenderer>

            <BlockRenderer Component={BlockImages} model={model}/>

            <BlockRenderer Component={BlockDescription} model={model}/>

            <BlockRenderer Component={BlockItineraryNotes} model={model}/>

            <BlockRenderer Component={BlockPrice} model={model}/>

            <BlockRenderer Component={BlockRemarks} model={model}/>

            <BlockRenderer Component={BlockTable} model={model}/>

            <BlockRenderer Component={BlockCombo} model={model}/>

        </div>
    )
};
