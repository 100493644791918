import React, {createRef, useEffect, useRef, useState} from "react";
import Scrollspy from "react-scrollspy";
import {useApiData} from "../hooks/useApiData";
import { default as Qlogo } from '../logo-white.svg';
import scrollToElement from "../helpers/scrollToElement";
import './MobileNavTop.scss';

function MobileNavTop({isItineraryVisible = false, sections = []}) {

    const navRef = useRef(null);

    const ref = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        // Step 3: Access the height after the component has mounted
        if (ref.current) {
            setHeight(ref.current.offsetHeight);
        }
    }, []); // Empty dependency array ensures this only runs once after initial render


    const screenHeight = window.innerHeight;

    const {data} = useApiData();

    const handleScroll = () => {
        const activeElement = document.querySelector('.pill.active');
        // console.log(activeElement);
        if (activeElement) {
            const leftOffset = activeElement.offsetLeft;
            const elementWidth = activeElement.offsetWidth;
            const containerWidth = navRef.current.offsetWidth;
            const scrollLeft = leftOffset - (containerWidth / 2) + (elementWidth / 2) -  elementWidth;
            navRef.current.scroll({ left: scrollLeft, behavior: 'smooth' });
        }
    };

    return (
        <div id="mobileNavTop" ref={ref} className={`MobileNavTop  ${isItineraryVisible ? "active" : ""}`}>
            <div className={"top"}>
                <div className={"logo"}>
                    <a href="#home" onClick={scrollToElement} ><img src={Qlogo} alt={"logo"}/></a>
                </div>
                <div className={"affix"} ref={navRef}>
                    <Scrollspy items={ sections } currentClassName="active" offset={-screenHeight} onUpdate={handleScroll}>
                        {data.days.map((row, index) => (
                            <li className={"pill"} key={index}>
                                <a ref={createRef()} onClick={scrollToElement} href={`#image-${index+1}`}>DAY {row.day_number}</a>
                            </li>
                        ))}
                        <li className={"pill"}>
                            <a href={`#`}></a>
                        </li>
                        <li className={"pill"}>
                            <a href={`#`}></a>
                        </li>
                    </Scrollspy>
                </div>
            </div>
            <div className={"bottom"}>Monday, 19th Jun 2023</div>
        </div>
    );
};

export default MobileNavTop;
