import React from "react";
import {BlockDescription, BlockItineraryNotes, BlockRenderer, BlockTitle} from "../blocks/Blocks";

export const BundleContent = ({model}) => {
    return (
        <div className={"BundleContent"}>
            <BlockRenderer Component={BlockTitle} model={model}/>

            <BlockRenderer Component={BlockDescription} model={model}/>

            <BlockRenderer Component={BlockItineraryNotes} model={model}/>
        </div>
    )
};
