import React from "react";
import {
    BlockAlternativeText,
    BlockBundle,
    BlockDescription,
    BlockImages,
    BlockItineraryNotes,
    BlockPrice,
    BlockRemarks,
    BlockRenderer,
    BlockTable,
    BlockTitle
} from "../blocks/Blocks";

export const Tour = ({model}) => {
    return (
        <div className={"Tour"}>
            <BlockRenderer Component={BlockTitle} model={model}/>

            <BlockRenderer Component={BlockAlternativeText} model={model}/>

            <BlockRenderer Component={BlockImages} model={model}/>

            <BlockRenderer Component={BlockDescription} model={model}/>

            <BlockRenderer Component={BlockItineraryNotes} model={model}/>

            <BlockRenderer Component={BlockPrice} model={model}/>

            <BlockRenderer Component={BlockBundle} model={model}/>

            <BlockRenderer Component={BlockRemarks} model={model}/>

            <BlockRenderer Component={BlockTable} model={model}/>

        </div>
    )
};
