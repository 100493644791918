export function BlockProposalBundle({model, className = ""}) {
    return (
        <div className={`BlockProposalBundle ${className}`}>
            { model.bundlelines_count > 0  && (
                <>
                    <div className="text proposal_includes mt20" dangerouslySetInnerHTML={{__html: model.proposal_includes}}></div>
                    <ul>
                        {model.including.map((inclusion, index) => (
                            <li key={index}>
                                {inclusion.quantity}
                                <span className="text single-line" dangerouslySetInnerHTML={{__html: inclusion.label}}></span>
                                {inclusion.alternatives.length && (
                                    <ul>
                                        {inclusion.alternatives.map((alternative, index) => (
                                            <li key={index}>
                                                or {alternative.quantity}
                                                <span className="editable">
                                                    <span className="text single-line" dangerouslySetInnerHTML={{__html: alternative.label}}></span>
                                                </span>
                                                {alternative.price_diff > 0 ? ' + ' : ' - '} {alternative.currency} {alternative.price_diff_abs}{alternative.unit ? ' per ' + alternative.unit : ''}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </>
            )}

            {model.proposal_options && (
                <>
                    <div className="text proposal_options" dangerouslySetInnerHTML={{__html: model.proposal_options}}></div>
                    <ul>
                        {model.options.map((option, index) => (
                            <li key={index}>
                                <span className="editable">
                                    <span className="text single-line" dangerouslySetInnerHTML={{__html: option.label}}></span>
                                </span>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </div>
);
}