import ImageBlock from "./ImageBlock";
import DayBlock from "./DayBlock";
import React from "react";

export function ItineraryDay({index, row, image_filter}) {
    return (
        <>
            <ImageBlock id={`image-${index+1}`} index={index} color={'#ccffcc'} title={row.title}
                        imagesrc={row.image} date={row.date} day_number={row.day_number}
                        image_filter={image_filter}></ImageBlock>
            <DayBlock id={row.id} day={row}></DayBlock>
        </>
    )
}