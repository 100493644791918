import {useState} from "react";
import {Icon} from "../Icon";
import "./ComboTransportation.scss";
import {ComponentBlock} from "./ComponentBlock";
import {BlockMap} from "../blocks/Blocks";
import {hasValidCoordinates} from "../../helpers/Coordinates";

export const ComboTransportation = ({model}) => {
    const [showSteps, setShowSteps] = useState(false);

    return (
        <div className={"ComboTransportation"}>
            <div className={"combotransportation-heading"}>
                <div className={"combotransportation-heading-title"}>
                    {model.origin}
                    <span className={"combotransportation-heading-arrow"}><Icon name={"arrow"}></Icon></span>
                    {model.destination}
                </div>
                <div className={`combotransportation-heading-button ${showSteps ? 'show' : ''}`}
                     onClick={() => setShowSteps(!showSteps)}>
                    {showSteps ? "HIDE ALL STEPS" : "SHOW ALL STEPS"}
                </div>
            </div>
            <div className={"combotransportation-summary"}>
                <div className={"combotransportation-summary-block"}>
                    <div className={"combotransportation-summary-block-title"}>Departure:</div>
                    <div className={"combotransportation-summary-block-value"}>{model.departure_time}</div>
                </div>
                <div className={"combotransportation-summary-block"}>
                    <div className={"combotransportation-summary-block-title"}>Arrival:</div>
                    <div className={"combotransportation-summary-block-value"}>{model.arrival_time}</div>
                </div>
                <div className={"combotransportation-summary-block compacted"}>
                    <div className={"combotransportation-summary-block-title"}>{model.departure_location.title}</div>
                    <div className={"combotransportation-summary-block-value"}>{model.departure_location.text}</div>
                </div>
                <div className={"combotransportation-summary-block compacted"}>
                    <div className={"combotransportation-summary-block-title"}>{model.arrival_location.title}</div>
                    <div className={"combotransportation-summary-block-value"}>{model.arrival_location.text}</div>
                </div>
            </div>

            {hasValidCoordinates(model) && <BlockMap model={model}/>}

            <div className={`combotransportation-steps ${showSteps ? 'show' : 'hidden'}`}>
                {model.steps.map((step, index) => (
                    <div key={index} className={"combotransportation-step"}>
                        <ComponentBlock item={step}/>
                    </div>
                ))}
            </div>
        </div>
    )
};
