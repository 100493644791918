import { getToken } from "firebase/messaging";
import { messaging } from "./firebaseConfig";


export function hasPermission() {
    //checking if the browser supports the Notification API
    if (!("Notification" in window)) {
        console.log("This browser does not support notifications.");
        return false;
    }

    //checking if the user has blocked the notifications
    if (Notification.permission === "denied") {
        console.log("The user has blocked the notifications.");
        return false;
    }

    //checking if the user has granted the permission to show notifications
    if (Notification.permission === "granted") {
        console.log("The user has granted the notifications.");
        return true;
    }

    //requesting the permission to show notifications
    if (Notification.permission !== "denied") {
        console.log("The user has denied the notifications.");
        // TODO: handle the case..
      return false;
    }


    return false;
}


export async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
        const token = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_VAPID,
        });

        //We can send token to server
        console.log("Token generated : " + token);
    } else if (permission === "denied") {
        //notifications are blocked
        console.log("You denied for the notification");
    }
}
