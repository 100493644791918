import {BlockParagraphTitle} from "./BlockParagraphTitle";

export function BlockUpsells({model, className = ""}) {
    if (!model.is_proposal || !model.upsells.length) {
        return <div className={`BlockUpsells ${className}`}></div>;
    }

    return (
        <div className={`BlockUpsells ${className}`}>
            <BlockParagraphTitle>Further Options:</BlockParagraphTitle>
            <ul>
                {model.upsells.map((upsell, index) => {
                    return (
                        <li key={index}>
                            <span className="editable">
                                <span className="text single-line" data-placeholder="Upsell Title" data-estimateline_id={upsell.estimateline_id} data-name="title">{upsell.label}</span>
                            </span>
                            <span className="proposal_price">{upsell.price_diff > 0 ? ' + ' : ' - '}{upsell.price_diff_abs} {upsell.currency}{upsell.unit ? ' per ' + upsell.unit : ''}</span><br />
                            <div className="editable">
                                <div className="text" data-placeholder="Upsell Description" data-estimateline_id={upsell.estimateline_id} data-name="description">{upsell.description}</div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}