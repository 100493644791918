import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useApiData} from "../../hooks/useApiData";

import './PdfModal.scss';
import {Icon} from "../Icon";

function PdfModal(props) {

    const {data} = useApiData();

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={`PdfModal`}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Download Pdf
                </Modal.Title>
            </Modal.Header>
            <div className={`pdfmodal-separator`}></div>
            <Modal.Body>
                <ul className={`pdfmodal-btns-list`}>
                    {data.estimates.map((estimate, index) => (
                        <li key={index}>
                            <a href={`${estimate.base_url}/bookmaker/book/download/id/${estimate.id}/format/pdf`}
                               target={"_blank"}>
                                <div className={"icon"}><Icon name={"arrow"}></Icon></div>
                                <div className={"text"}>{estimate.itinerary_dates} - <span
                                    className={'country'}>{estimate.itinerary_country}</span></div>
                            </a>
                        </li>
                    ))}
                </ul>
            </Modal.Body>
            {/*<Modal.Footer>*/}
            {/*    <Button onClick={props.onHide}>Close</Button>*/}
            {/*</Modal.Footer>*/}
        </Modal>
    );
}

export default PdfModal;
