import {BlockImage} from "./BlockImage";
import "./BlockImages.scss";
import {clone, forEach, contains,sortBy} from "underscore";
import {BlockSlider} from "./BlockSlider";
import {BlockVideo} from "./BlockVideo";
import {useApiData} from "../../hooks/useApiData";
import ImageService from "../../services/ImageService";
import {useState} from "react";

export function BlockImages({model, className = ""}) {

    const {editable,fetchData} = useApiData();

    const imageService = new ImageService();
    imageService.setEstimateline(model);

    const extra_class = (editable)? 'editable' : '';

    // the images the user has selected
    let user_images = model.itinerary_selected_images||[];

    // function to determine if an image is included in the selected images
    const isSelected = (image) => {
        const user_image_ids = user_images.map((image) => parseInt(image.id));
        return contains(user_image_ids, parseInt(image.id));
    }

    // available images (images the user can select)
    let available_images = model.itinerary_images||[];

    // the editable_images are the user images plus the in selected available images
    let editable_images = clone(user_images);
    const user_image_ids = user_images.map((image) => parseInt(image.id));
    forEach(available_images, (image) => {
        if(!contains(user_image_ids, parseInt(image.id))){
            editable_images.push(image);
        }
    });

        // itinerary_selected_images
    let the_images = editable ? editable_images: user_images ;

    const [items, setItems] = useState(the_images);

    // how many images
    const length = items.length;

    const requires_slider = length > 6;
    const requires_mosaic = length >= 2;
    const single = length === 1;


    // sot the  images to display starting from the ones isSelected
    // the_images = sortBy(the_images, (image) => {
    //     return isSelected(image) ? 0 : 1;
    // });


    const getStyle = (image) => {
        return isSelected(image) ? '' : 'editable';
    }

    function onImageSelection(image) {

        const me = this;

        if (editable) {

            const endpoint = 'estimatelinefile';

            const data = {
                'file_id': image.id,
                'estimateline_id': model.id
            }

            const is_selected = isSelected(image);

            if(is_selected){

                imageService.delete(endpoint, data).then((response) => {
                    me.forceUpdate();
                }).catch((error) => {
                    console.error('error', error);
                });

            } else {

                data.order = 1;

                imageService.patch(endpoint, data).then((response) => {
                }).catch((error) => {
                    console.error('error', error);
                });
            }

        }
    }


    const handleDragStart = (e, index) => {
        if (!editable) {
            e.preventDefault();
            return;
        }
        e.dataTransfer.setData("text/plain", index);
    };

    const handleDrop = (e, index) => {
        if (!editable) return;

        e.preventDefault();
        const movingItemIndex = e.dataTransfer.getData("text/plain");
        console.error('movingItemIndex', movingItemIndex);
        console.error('index', index);
        const itemToMove = items[movingItemIndex];
        console.error('itemToMove', itemToMove);
        const newItems = items.filter((_, idx) => idx !== movingItemIndex);
        newItems.splice(index, 0, itemToMove);
        setItems(newItems);

        const data = {
            'file_id': itemToMove.id,
            'estimateline_id': model.id
        }
        data.order = index;

        imageService.patch('estimatelinefile', data).then((response) => {
        }).catch((error) => {
            console.error('error', error);
        });


    };

    const handleDragOver = (e) => {
        if (!editable) return;

        e.preventDefault(); // Necessary to allow dropping
    };



    if(!length) {
        return null;
    }

    if (requires_slider) {
        return (
            <div className={`BlockImages ${className}`}>
                 <BlockSlider model={model} files={items} className={extra_class}/>
            </div>
        )
    }



    if (requires_mosaic) {
        return (
            <div className={`BlockImages grid gap-1 ${className}`}>
                {/*<div className="g-col-12 g-col-md-12" onClick={(e)=>onImageSelection(firstImage)}>*/}
                {/*    {firstImage.type === 'video' && <BlockVideo className={"video-lg"} src={firstImage.url_large}  volume={firstImage.volume} caption={firstImage.caption}/>}*/}
                {/*    {firstImage.type === 'image' && <BlockImage src={firstImage.url_large} caption={firstImage.caption} className={getStyle(firstImage)}/>}*/}
                {/*</div>*/}
                {items.map((image, index) => (<div key={index} className={(index == 0) ? 'g-col-12 g-col-md-12' : 'g-col-12 g-col-md-6'}
                         draggable={editable}
                         onDragStart={(e) => handleDragStart(e, index)}
                         onDragOver={editable ? handleDragOver : undefined}
                         onDrop={editable ? (e) => handleDrop(e, index) : undefined}
                         onClick={(e)=>onImageSelection(image)}>
                        {image.type === 'video' && <BlockVideo className={"video-sm"} src={image.url} volume={image.volume} caption={image.caption}/>}
                        {image.type === 'image' && <BlockImage src={image.url_large} caption={image.caption} className={getStyle(image)}/>}
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className={`BlockImages grid gap-1 ${className}`}>
            {items.map((image, index) => (
                <div key={index} className={`g-col-12 g-col-md-${single ? '12' : '6'}`} onClick={(e)=>onImageSelection(image)}>
                    {image.type === 'video' && <BlockVideo className={`video-${single ? 'single' : 'sm'}`} src={image.url}  volume={image.volume} caption={image.caption}/>}
                    {image.type === 'image' && <BlockImage src={single ? image.url_large : image.url_small} caption={image.caption} className={getStyle(image)}/>}
                </div>
            ))}
        </div>
    );
}
