import React from "react";
import {
    BlockAlternativeText,
    BlockDescription,
    BlockImages,
    BlockItineraryNotes, BlockPrice, BlockRemarks, BlockRenderer,
    BlockSubtitle, BlockTable,
    BlockTitle
} from "../blocks/Blocks";

export const Attraction = ({model}) => {
    return (
        <div className={"Attraction"}>
            <BlockRenderer Component={BlockTitle} model={model}/>

            <BlockRenderer Component={BlockSubtitle} model={model}>
                {model.provider_label && <div>{model.provider_label}</div>}
                {model.address && <div><span>Address:</span> {model.address}</div>}
                {model.voucherdataarr.bookingref && <div>Reservation number: <strong
                    dangerouslySetInnerHTML={{__html: model.voucherdataarr.bookingref}}></strong></div>}
            </BlockRenderer>

            <BlockRenderer Component={BlockAlternativeText} model={model}/>

            <BlockRenderer Component={BlockImages} model={model}/>

            <BlockRenderer Component={BlockDescription} model={model}/>

            <BlockRenderer Component={BlockItineraryNotes} model={model}/>

            <BlockRenderer Component={BlockPrice} model={model}/>

            <BlockRenderer Component={BlockRemarks} model={model}/>

            <BlockRenderer Component={BlockTable} model={model}/>

        </div>
    )
};
