import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

class ImageService {

    constructor() {

    }

    async fetchData(endpoint) {

        const options = {
            headers: {
                'api-key': process.env.REACT_APP_API_KEY,
            },
        }

        try {
            const response = await axios.get(`${this.api_endpoint}/${endpoint}`, options);
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }

    }

    async patch(endpoint, params) {

        const options = {
            headers: {
                'api-key': process.env.REACT_APP_API_KEY,
            },
        }

        try {
            const response = await axios.patch(`${this.api_endpoint}/${endpoint}`, params, options);
            return response.data;
        } catch (error) {
            console.error('Error patching data:', error);
            throw error;
        }
    }

    async delete(endpoint, params) {

        const options = {
            headers: {
                'api-key': process.env.REACT_APP_API_KEY,
            },
        }

        try {
            let deleteUrl = `${this.api_endpoint}/${endpoint}`;
            options.data = params;
            const response = await axios.delete(`${deleteUrl}`, options);
            return response.data;
        } catch (error) {
            console.error('Error patching data:', error);
            throw error;
        }
    }

    setEstimateline(model) {
        this.api_endpoint = model.api_endpoint;
    }
}

export default ImageService;
