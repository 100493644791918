import React from "react";
import LoremIpsum from "react-lorem-ipsum";

export const Guides = ({model}) => {
    return (
        <div className={"Guides"}>
            <h1>This is the block to be used for Guides</h1>
            <LoremIpsum p={3}/>
        </div>
    )
};
