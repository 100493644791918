import React, {useContext, useEffect} from 'react';
import Splash from "./Splash";
import GlanceImage from "./GlanceImage";
import GlanceText from "./GlanceText";
import WelcomeLetter from "./WelcomeLetter";
import Sidebar from "./Sidebar";
import DayAffix from "./DayAffix";
import Gratuities from "./Gratuities";
import Terms from "./Terms";
import {useInView} from "react-intersection-observer";
import Loading from "./Loading";
import {DataContext} from "../context/DataContext";
import {useParams, useSearchParams} from "react-router-dom";
import MobileNavTop from "./MobileNavTop";
import MobileNavBottom from "./MobileNavBottom";
import _ from "underscore";
import PdfModal from "./navigation/PdfModal";
import CallModal from "./navigation/CallModal";
import {LoadScript} from "@react-google-maps/api";
import {ItineraryDay} from "./ItineraryDay";
import Notifications from "../firebase/Notifications";
import {useApiData} from "../hooks/useApiData";

function ItineraryTool() {

    // use the context
    const {data, loading, error, fetchData, editable,setEditable, setItineraryGuid} = useContext(DataContext);

    // the guid in the url. This is used to fetch the data from the api
    const {guid} = useParams();
    const [searchParams] = useSearchParams();

    const backToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }

    const [downloadModalShow, setDownloadModalShow] = React.useState(false);
    const [callModalShow, setCallModalShow] = React.useState(false);
    const [chatVisible, setChatVisible] = React.useState(false);

    const image_filter = 'filter-lens';


    const maps_libraries = [];



    useEffect(() => {
        setItineraryGuid(guid);
    }, []);


    // the variable inView will determine the visibility of the navigation bars
    const {ref, inView, entry} = useInView({
        threshold: 0,
        onChange: (inView, entry) => {
            // console.log(inView, entry);
        },
    });

    // Handle Loading
    if (loading) {
        return <Loading/>
    }

    // Handle error
    if (error) {
        return <Loading error_message={error.message}/>
    }
    // uncomment to develop directly
    // return <Loading error_message={"error.message"}/>

    let sections;

    if (data) {
        sections = _.pluck(data.days, 'id');
    }

    return (
        <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
            <Notifications/>
        <div id="home" className={`App ItineraryTool ${inView ? "active" : ""}`}>


            {/*<VersionChangeAlert guid={guid}/>*/}
            <Sidebar isItineraryVisible={inView} backToTop={backToTop} openDownload={setDownloadModalShow} openChat={setChatVisible} openCall={setCallModalShow}/>
            <DayAffix data={data} sections={sections} isItineraryVisible={inView}/>
            <MobileNavTop isItineraryVisible={inView} sections={sections}/>
            <MobileNavBottom isItineraryVisible={inView} backToTop={backToTop} openDownload={setDownloadModalShow} openChat={setChatVisible} openCall={setCallModalShow} />

            <Splash image_filter={image_filter}/>

            <div id={"at-glance"} className={"AtGlance"}>
                <GlanceImage/>
                <GlanceText/>
            </div>

            <WelcomeLetter/>




            <div ref={ref} id={"itinerary"} className={"ItineraryContainer"} data-bs-spy="scroll"
                 data-bs-target="#day-affix" data-bs-offset="0">


                {data.days.map((row, index) => (
                    <React.Fragment key={index}>
                        <ItineraryDay row={row} index={index} image_filter={image_filter}/>
                    </React.Fragment>
                ))}


            </div>


            <div className={"LastRow"}>
                <Gratuities/>
                <Terms/>
            </div>


            <PdfModal show={downloadModalShow} onHide={() => setDownloadModalShow(false)}/>

            <CallModal show={callModalShow} onHide={() => setCallModalShow(false)}/>


        </div>
        </LoadScript>
    );
}

export default ItineraryTool;
