import React from "react";
import {RoundedBox} from "../RoundedBox";
import {PropertyTable} from "../PropertyTable";
import {
    BlockDescription,
    BlockImages,
    BlockItineraryNotes,
    BlockPrice,
    BlockRemarks,
    BlockTransportTitle
} from "../blocks/Blocks";

export const BoatAsDirected = ({model}) => {
    return (
        <div className={"BoatAsDirected"}>
            <RoundedBox>

                <BlockTransportTitle model={model}/>

                <PropertyTable data={model.voucher}></PropertyTable>

                <BlockImages model={model}/>

                <BlockDescription model={model}/>

                <BlockItineraryNotes model={model}/>

                <BlockPrice model={model}/>

                <BlockRemarks model={model}/>

            </RoundedBox>
        </div>
    )
};
