import React, { createContext, useState } from 'react';

export const DataContext = createContext();


export const DataProvider = ({ children }) => {

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;


    let guid = null;

    // is the itinerary editable? Defaults to false.
    const [editable, setEditable] = useState(false);

    // are we in proposal view?
    const [isProposal, setIsProposal] = useState(false);


    const setItineraryGuid = (inguid) => {
        guid = inguid;
        fetchData();
    }

    const fetchData = async () => {

        if(!guid){
            return;
        }
        const url_toload = `${apiUrl}/itinerary/` + guid;

        // console.log(url_toload);

        const opts = {
            headers: {
                'api-key': process.env.REACT_APP_API_KEY,
            },
        }

        try {
            const response = await fetch(url_toload, opts);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            setData(result); // Set the data in state

        } catch (e) {
            setError(e); // If we catch any errors, set an error state
        } finally {

            if(!editable){
                // wait 3 seconds more
                //await new Promise(r => setTimeout(r, 3000));
            }

            setLoading(false); // Set loading to false regardless of outcome
        }
    };

    return (
        <DataContext.Provider value={{ data, loading, error, fetchData, setEditable, editable, isProposal, setItineraryGuid }}>
            {children}
        </DataContext.Provider>
    );
};
