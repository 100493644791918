import "./PropertyTableValue.scss";

export function PropertyTableValue({value}) {
    if(typeof value === "string") {
        return <div className={"PropertyTableValue"} dangerouslySetInnerHTML={{__html: value }}></div>;
    }
    return (
        <div className={"PropertyTableValue property-table-value-split"}>
            {value.title && <div className={"fw-bold"}>{value.title.toString()}</div>}
            {value.text && <div dangerouslySetInnerHTML={{__html: value.text}}></div>}
        </div>
    );
}