import { useContext } from 'react';
import { DataContext } from '../context/DataContext';

export const useApiData = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useApiData must be used within a DataProvider');
    }
    return context;
};
