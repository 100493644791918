import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {GoogleMap, DirectionsService, DirectionsRenderer, MarkerF} from '@react-google-maps/api';
import './BlockMap.scss';


const containerStyle = {
    width: '100%',
    height: '400px'
};


export function BlockMap({model, className = ""}) {

    const Google = window.google;

    const [map, setMap] = useState(null);

    const mapStyles = [
        {
            featureType: "all",
            elementType: "all",
            // stylers: [{ saturation: -90 }, { gamma: 0.5 }]
        }
    ];

    if (model.coordinates.from) {
        model.coordinates = [model.coordinates];
    }


    const coordinates = model.coordinates || [];
    // const coordinates = [
    //     {
    //         from: {lat: 45.83, lng: 9.41},
    //         to: {lat: 45.85, lng: 9.40}
    //     },
    //     {
    //         from: {lat: 45.85, lng: 9.40},
    //         to: {lat: 45.86, lng: 9.38}
    //     },
    //     {
    //         from: {lat: 45.86, lng: 9.38},
    //         to: {lat: 45.92, lng: 9.31}
    //     }
    // ];

    const firstCoordinate = coordinates[0];
    // Last coordinate is the last element of the array, if it exists, otherwise it's the same as the first coordinate
    const lastCoordinate = coordinates.length > 1 ? coordinates[coordinates.length - 1] : firstCoordinate;


    const mapOptions = {
        travelMode: Google.maps.TravelMode.TRANSIT,
        transitOptions: {
            departureTime: new Date(),
            modes: [Google.maps.TransitMode.TRAIN],
        },
        styles: mapStyles,
        disableDefaultUI: true,
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        rotateControl: false,
        panControl: true,
        gestureHandling: 'cooperative',
        clickableIcons: false
    };

    let waypoints = [];

    // Fit bounds function
    const fitBounds = () => {

        if (map) {

            // If more than 2 coordinates add waypoints
            // if ( model.coordinates.length >= 2) {
            //     let coo =  model.coordinates.slice(1, -1);
            //     waypoints = coo.map((coordinate) => {
            //         return {
            //             location: coordinate.to,
            //             stopover: true
            //         }
            //     });
            // }

            let bounds = new Google.maps.LatLngBounds();

            model.coordinates.forEach((coordinate) => {
                if (coordinate.from && coordinate.from.lat) {
                    bounds.extend(coordinate.from);
                }
                if (coordinate.to && coordinate.to.lat) {
                    bounds.extend(coordinate.to);
                }
            });

            map.fitBounds(bounds);
        }

    };

    const onGoogleMapLoad = useCallback((map) => setMap(map), []);

    useEffect(() => {
        fitBounds();
    }, [map, model.coordinates]);



    /**
     * Draw a flight
     * https://jsfiddle.net/wkk66jyk/
     */
    const drawFlight = function (start, end) {

        if(map) {

            var lineSymbol = {
                path: Google.maps.SymbolPath.FORWARD_CLOSED_ARROW
            };

            var poly = new Array();
            poly.push(start);
            poly.push(end);

            var flowline = new Google.maps.Polyline({
                map: map,
                path: poly,
                geodesic: true,
                strokeColor: "#3379d5",
                strokeOpacity: .8,
                strokeWeight: 2,
                icons: [{
                    icon: lineSymbol,
                    offset: '100%'
                }],
            });

            flowline.setMap(map);
        }
    };

    const flightCoordinates = model.coordinates.filter((coordinate) => {
        return coordinate.scat === 'flight';
    });

    flightCoordinates.forEach((coordinate) => {
        drawFlight(coordinate.from, coordinate.to);
    });

    const nonFlightCoordinates = model.coordinates.filter((coordinate) => {
        return coordinate.scat !== 'flight';
    });

    const directionService = new Google.maps.DirectionsService();

    const gresponses = useMemo(() => {

        let responses = [];

        nonFlightCoordinates.forEach((coordinate) => {

            let request = {
                origin: coordinate.from,
                destination: coordinate.to,
                drivingOptions: {
                    departureTime: new Date(coordinate.datetime_start),
                    trafficModel: 'pessimistic'
                },
                travelMode: Google.maps.TravelMode.DRIVING,
                unitSystem: Google.maps.UnitSystem.METRIC
            };

            if (coordinate.scat === 'train') {
                request.travelMode = Google.maps.TravelMode.TRANSIT;
                request.transitOptions = {
                    departureTime: new Date(),
                        modes: [Google.maps.TransitMode.TRAIN],
                };
            }

            directionService.route(request, (result, status) => {
                if (status === 'OK') {
                    responses.push({
                        options: {
                            markerOptions: {
                                icon: {
                                    path: Google.maps.SymbolPath.CIRCLE,
                                    scale: 5,
                                    fillColor: (coordinate.scat === 'train') ? 'blue' : 'red',
                                    fillOpacity: 1,
                                    strokeColor: 'white',
                                    strokeWeight: 1
                                }
                            },
                            polylineOptions: {
                                strokeColor: (coordinate.scat === 'train') ? 'blue' : 'red',
                                strokeOpacity: 0.3,
                                strokeWeight: 4
                            }
                        },
                        directions: result
                    });
                }
            });
        });

        return responses;
    }, nonFlightCoordinates);


    return (
        <div className={`BlockMap ${className}`}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                options={mapOptions}
                onLoad={onGoogleMapLoad}

            >
                {gresponses && gresponses.map((gresponse, index) => {
                    return <DirectionsRenderer key={`res${index}`} options={gresponse.options} directions={gresponse.directions}/>
                })}

                {/* Adding a marker if there's no destination */}
                {/*{!firstCoordinate.to && <MarkerF position={firstCoordinate.from}/>}*/}
            </GoogleMap>
        </div>
    );
}
