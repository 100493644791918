import React from 'react';
import LoremIpsum from "react-lorem-ipsum";
import {useApiData} from "../hooks/useApiData";

function Gratuities() {

    const {data, editable} = useApiData();

    return (
        <div id={"gratuities"} className="Gratuities Leftblock Textblock colsize">
            <div className={"remark title"}>Gratuities</div>
            <div dangerouslySetInnerHTML={{__html:data.gratuities}}></div>
        </div>
    );
};

export default Gratuities;
