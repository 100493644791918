import React from 'react';

function ImageBlock({id, index, color, title, imagesrc, weekday='Friday', date='May 14', day_number='01', image_filter='filter-maven'}) {


    const style = {
        backgroundImage: `linear-gradient(to bottom, rgb(14, 36, 54) 0%, transparent 20%), url(${imagesrc})`,
    };


    return (
        <div id={id}  style={style} className={`ImageBlock ${image_filter} fullsize`}>

            <div className={"info"}>
                <div className={"day"}>DAY {day_number}</div>
                <div className={"where"}>{title}
                    <div className={"date"}>{weekday}, {date}</div>
                </div>

            </div>
        </div>
    );
};

export default ImageBlock;
