import Slider from "react-slick";
import {BlockImage} from "./BlockImage";
import {BlockVideo} from "./BlockVideo";

import {useState} from "react";
import "./BlockSlider.scss";
import {useApiData} from "../../hooks/useApiData";

export function BlockSlider({model, files, className = ""}) {

    const {editable} = useApiData();

    const extra_class = (editable)? 'editable' : '';

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        lazyLoad: true,
        // swipeToSlide: true,
        // fade: true,
        slidesToScroll: 1,
        draggable: false,
        height: '100%',
        width: '100%',
    };

    // Use to stop videos when passing to next slide
    const [playVideos, setPlayVideos] = useState(false);


    const isVideo = file => file.type === 'video';
    // Before slide change if the current one is a video we stop it
    settings.beforeChange = current => {
        if (isVideo(files[current])) {
            setPlayVideos(false)
        }
    }

    const onVideoPlay = () => {
        setPlayVideos(true);
    }


    return (
        <div className={`BlockSlider ${className}`}>
            <Slider {...settings}>
                {files.map((row, index) => (
                    <div key={`slider-${index+1}`}>
                        {row.type === 'video' && <BlockVideo
                            className={"video-lg"}
                            src={row.url}
                            playing={playVideos}
                            onPlay={onVideoPlay}
                            volume={row.volume}
                            caption={row.caption}
                        />}
                        {row.type === 'image' && <BlockImage src={row.url_large} caption={row.caption} className={extra_class}/>}
                    </div>
                ))}
            </Slider>
        </div>
    );
}
