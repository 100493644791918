import React from 'react';
import {useApiData} from "../hooks/useApiData";
import { default as Qlogo } from '../logo.svg';
import scrollToElement from "../helpers/scrollToElement";


function WelcomeLetter() {

    const {data} = useApiData();

    return (
        <div id="welcome-letter" className="WelcomeLetter d-inline-flex">
            <div className={"left brand"}>
                <div className="wltitle">
                    WELCOME<br/>LETTER
                </div>
                <div className="logo">
                    <a href={"#home"} onClick={scrollToElement}><img src={Qlogo} /></a>
                </div>
            </div>
            <div className={"right  p-5"}>
                <div className={"dear"}>Dear {data.travellers_fullname}</div>
                <div className={"welcome_text"}>
                    {data.welcome_letter.split("\n").map((item, key) => {
                        return <p key={key}>{item}</p>
                    })}
                    <div className={"signature"}>
                        <div className={"sincerely"}>Yours Sincerely</div>
                        Queen of Clubs Team
                    </div>
                </div>

            </div>
        </div>
    );
};
export default WelcomeLetter;
