import React, {useRef} from 'react';
import { default as Qlogo } from '../logo.svg';
import TypewriterComponent from "typewriter-effect";
function Loading({error_message=''}) {


    let  strings = [];

    if (error_message == '') {
        strings = [
            "Loading nice views...",
            "Loading nice places to eat...",
            "Loading memorable experiences...",
            "Loading the best places to stay...",
            "Loading awesome places to explore...",
            "Loading interesting things to do...",
        ];

        // shuffle the strings
        strings.sort(() => Math.random() - 0.5);

    } else {
        strings = [error_message];
    }



    return (
        <div className={"Loading"}>
            <div className={"spinner"}>
                {/*<img src={Qlogo} />*/}
                <div className="loader"></div>
            </div>

            <div className={"message"}>
                {strings.length > 1 && <TypewriterComponent
                    options={{
                        strings: strings,
                        delay: 5,
                        pauseFor: 500,
                        deleteSpeed: 5,
                        autoStart: true,
                        loop: false,
                    }}></TypewriterComponent>}

                {strings.length == 1 && <p>{strings[0]}</p>}

            </div>
        </div>
    );
};

export default Loading;
