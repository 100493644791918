import React from "react";
import "./BlockImage.scss";

export function BlockImage({src, caption = '', className = ""}) {

    const [showCaption, setShowCaption] = React.useState(false);

    const toggleCaption = (show) => {
        setShowCaption(show);
    }

    return (
        <div className={`BlockImage ${className}`}>
            <img src={src}
                 draggable={false}
                 alt={caption}
                 className="img-fluid"
                 onMouseEnter={() => toggleCaption(true)}
                 onMouseLeave={() => toggleCaption(false)}
                 loading="lazy"
            />
            {/* We need to bind the toggling of the caption to itself
            also because it stays over the image and changing
            this would have unwanted effects such as flickering */}
            {caption && (<div className={`caption-container ${showCaption ? 'show' : 'hidden'}`}
                              onMouseEnter={() => toggleCaption(true)}
                              onMouseLeave={() => toggleCaption(false)}>
                <div className="caption">
                    {caption}
                </div>
            </div>)}
        </div>
    );
}
