import {Icon} from "../Icon";
import "./BlockIncluding.scss";

export function BlockIncluding({model, className = ""}) {
    if(!model.including || !model.including.length) {
        return <div className={`BlockIncluding ${className}`}></div>;
    }
    return (
        <div className={`BlockIncluding ${className}`}>
            <div className={"blockincluding-title"} dangerouslySetInnerHTML={{__html: model.proposal_includes}}></div>
            <div className={"blockincluding-list"}>
                {model.including.map((item, index) => (
                    <div className={"blockincluding-item"} key={index}>
                        <div className={"blockincluding-item-icon"}>
                            <Icon name={item.scat}></Icon>
                        </div>
                        <div className={"blockincluding-item-text"}>{ item.quantity } { item.label }</div>
                    </div>
                ))}
            </div>
        </div>
    )
}
