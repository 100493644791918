import React from "react";
import {
    BlockDescription,
    BlockImages,
    BlockItineraryNotes,
    BlockPrice,
    BlockRemarks,
    BlockTransportTitle
} from "../blocks/Blocks";
import {PropertyTable} from "../PropertyTable";

export const JetsHelicopters = ({model}) => {
    return (
        <div className={"JetsHelicopters"}>
            <BlockTransportTitle model={model}/>

            <PropertyTable data={model.voucher}></PropertyTable>

            <BlockImages model={model}/>

            <BlockDescription model={model}/>

            <BlockItineraryNotes model={model}/>

            <BlockPrice model={model}/>

            <BlockRemarks model={model}/>

        </div>
    )
};
