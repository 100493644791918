import React, {createRef, useState} from "react";
import Scrollspy from 'react-scrollspy'
import {useApiData} from "../hooks/useApiData";
import scrollToElement from "../helpers/scrollToElement";


// TODO: check why this is a forwardref..

function DayAffix({sections, isItineraryVisible}) {

    const [open, setOpen] = useState(false);

    const {data} = useApiData();


    const handleScroll = () => {
        const activeElement = document.querySelector('.pill.active');
        // console.log(activeElement);
        // if (activeElement) {
        //     const leftOffset = activeElement.offsetLeft;
        //     const elementWidth = activeElement.offsetWidth;
        //     const containerWidth = navRef.current.offsetWidth;
        //     const scrollLeft = leftOffset - (containerWidth / 2) + (elementWidth / 2) + 10;
        //     navRef.current.scroll({ left: scrollLeft, behavior: 'smooth' });
        // }
    };


    return (
        <div id={"day-affix"} className={`DayAffix  ${isItineraryVisible ? "active" : ""}`}>
            <div className="cont">
                <Scrollspy items={sections} currentClassName="active" onUpdate={handleScroll}>
                    {data.days.map((row, index) => (
                        <li key={index}>
                            <a ref={createRef()} onClick={scrollToElement} href={`#${row.id}`}>{row.day_number}</a>
                        </li>
                    ))}
                </Scrollspy>
            </div>

        </div>
    )
};
export default DayAffix;
