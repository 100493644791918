import {Icon} from "../Icon";
import React from "react";
import "./BlockTransportTitle.scss";

export function BlockTransportTitle({model, icon, className = ""}) {
    return (
        <div className={`BlockTransportTitle ${className}`}>
            <div className={"title"} dangerouslySetInnerHTML={{__html: model.title}}></div>
            <div className={"icon"}>
                <a href={model.edit_url} target="_blank" rel="noreferrer">
                    <Icon name={model.scat} tootltip={model.servicecategory}></Icon>
                </a>
            </div>
        </div>
)
}
