import React, {useContext} from "react";
import Hamburger from "./Hamburger";
import {useApiData} from "../hooks/useApiData";

function Sidebar(params) {

    const {editable, setEditable} = useApiData();

    return (
        <div className={`Sidebar ${params.isItineraryVisible ? "active" : ""}`}>
            <div className={"cont"}>


                <div className={"hamburgerbar"}>
                    <button className={"iconbutton"} onClick={() => params.backToTop()}>
                        <Hamburger className={"light"}/>
                    </button>
                </div>

                <div className={"buttonbar"}>
                    <button className={"iconbutton"} onClick={() => params.openCall(true)}><i className={"qoc-contact"}></i></button>
                    <button className={"iconbutton"} onClick={() => params.openChat(true)}><i className={"qoc-chat"}></i></button>
                    <button className={"iconbutton"} onClick={() => params.openDownload(true)}><i className={"qoc-arrow"}></i></button>
                    <button className={"iconbutton"} onClick={() => setEditable(!editable)}><i className={"qoc-chat"}></i></button>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
