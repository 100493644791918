import ContentEditable from "react-contenteditable";
import {useRef, useState} from "react";
import "./BlockEditableText.scss";
import {useApiData} from "../../hooks/useApiData";

export function BlockEditableText({model, attribute, disabled = false, className = ""}) {
    const {editable} = useApiData();
    disabled = disabled || !editable;

    const [initialValue, setInitialValue] = useState(model[attribute] ? model[attribute] : "");
    const text = useRef(initialValue);
    const [success, setSuccess] = useState(null);

    const api = {
        save: async () => {

            //TODO: save the text to the server

            // For now, we just set a timeout the after 0.5s returns true or false randomly
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (Math.random() > 0.5) {
                        resolve(true);
                    } else {
                        reject(false);
                    }
                }, 500);
            });
        }
    };

    const handleChange = evt => {
        text.current = evt.target.value;
    };

    const handleBlur = () => {
        if (text.current === initialValue) {
            return;
        }
        api.save().then(() => {
            setInitialValue(text.current);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(null);
            }, 1000);
        }).catch(() => {
            setSuccess(false);
            setTimeout(() => {
                setSuccess(null);
            }, 1000);
        });
    };

    const classes = ["BlockText", className];
    if (success === true) {
        classes.push("success");
    }
    if (success === false) {
        classes.push("error");
    }


    return (
        <ContentEditable className={classes.join(" ")}
                         disabled={disabled}
                         html={text.current}
                         onChange={handleChange}
                         onBlur={handleBlur}/>
    )
}
