import React from "react";
import Hamburger from "./Hamburger";
import './MobileNavBottom.scss';

function MobileNavBottom(params) {

    return (
        <div className={`MobileNavBottom ${params.isItineraryVisible? "active":""}`} >
            <div className={"wrapper"}>
                <div className="cont">
                    <div className={"left"}>
                        <Hamburger className={"dark small iconbutton"} onClick={() => params.backToTop()}></Hamburger>
                    </div>
                    <div className={"right"}>
                        <button className={"iconbutton"} onClick={() => params.openCall(true)}><i
                            className={"qoc-contact"}></i></button>
                        <button className={"iconbutton"} onClick={() => params.openChat(true)}><i
                            className={"qoc-chat"}></i></button>
                        <button className={"iconbutton"} onClick={() => params.openDownload(true)}><i
                            className={"qoc-arrow"}></i></button>
                    </div>
                </div>

            </div>
        </div>
    );
};


export default MobileNavBottom;
