export default function scrollToElement(e) {

    const href= e.currentTarget.getAttribute('href');

    if(href){
        const elementId = href.substr(1);

        // Get the target element
        let element = document.getElementById(elementId);

        if (element) {

            console.debug('scrollToElement', elementId, element);
            // Get the bounding rectangle of the target element
            const elementPosition = element.getBoundingClientRect().top;

            const navigationBar = document.getElementById('mobileNavTop');

            let navigationBarHeight = 0;
            if(navigationBar.classList.contains('active')){
                // Get the height of the sticky navigation bar
                navigationBarHeight = navigationBar.offsetHeight;
            }

            // Calculate the correct position
            const offsetPosition = elementPosition + window.pageYOffset - navigationBarHeight;

            console.debug('scrollToElement', offsetPosition);

            // element.scrollIntoView({ behavior: 'smooth', block: 'start' })

            // // Smooth scroll to the calculated position
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }


    }




    e.preventDefault();
}
