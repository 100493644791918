import React from "react";
import {BlockEditableText} from "./BlockEditableText";

export function BlockAlternativeText({model, className = ""}) {
    if (!model.is_proposal || !model.is_alternative) {
        return <div className={`BlockAlternativeText ${className}`}></div>;
    }

    return (
        <div className={`BlockAlternativeText, ${className}`}>
            <BlockEditableText model={model} attribute="proposal_alternative_text"></BlockEditableText>
        </div>
    );
}