import {BlockText} from "./BlockText";
import React from "react";

export function BlockPrice({model, className = ""}) {
    if (!model.is_proposal || model.is_package || model.combos.length) {
        return <div className={`BlockPrice ${className}`}></div>;
    }


    return (
        <div className={`BlockPrice ${className}`}>
            <BlockText text={`Rate - ${model.proposal_price}`}></BlockText>
        </div>
    );
}