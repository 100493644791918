import React, { useState } from 'react';
import {hasPermission, requestPermission} from "./requestToken";

const PermissionUx = () => {
    // State to manage the checkbox
    const [isChecked, setIsChecked] = useState(hasPermission);

    function onUserChoice(checked) {
        setIsChecked(checked);
        requestPermission();
    }

    return (
        <div className="panel">
            <div>
            <label>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={(e) => onUserChoice(e.target.checked)}
                />
                Do you want to receive update notifications about this itinerary?
            </label>
            </div>
        </div>
    );
};

export default PermissionUx;
