import React from "react";
import {RoundedBox} from "../RoundedBox";
import {PropertyTable} from "../PropertyTable";
import {
    BlockDescription,
    BlockImages,
    BlockItineraryNotes,
    BlockPrice,
    BlockRemarks,
    BlockTransportTitle
} from "../blocks/Blocks";

export const Coach = ({model}) => {

    return (
        <div className={"Coach"}>

            <RoundedBox>
                <BlockTransportTitle model={model}/>

                <PropertyTable data={model.voucher}></PropertyTable>

                <BlockDescription model={model}/>

                {/*<BlockImages model={model} />*/}

                <BlockItineraryNotes model={model}/>

                <BlockPrice model={model}/>

                <BlockRemarks model={model}/>

            </RoundedBox>
        </div>
    )
};
