import React, {useState} from 'react';
import {useApiData} from '../hooks/useApiData';
import { default as Qlogo } from '../logo.svg';
import scrollToElement from "../helpers/scrollToElement";

function Splash({image_filter}) {

    const {data, editable} = useApiData();

    const [showEstimates, setShowEstimates] = useState(false);

    return (
        <div style={{backgroundImage: `url(${data.cover_image})`}} className={`Splash fullsize  ${image_filter}`}>
            <div className={"cont"}>

                <div className="agencyLogo text-center">
                    <a href="#home" onClick={scrollToElement} ><img className="img-responsive" src={data.agency_logo || Qlogo} /></a>
                </div>

                <div className="customerBox position-absolute">
                    {data.agent_is_collaboration &&
                        <div className={"association"}>{data.collaboration} {data.agent_name}, for</div>}
                    <div className={"customer"}>{data.travellers_fullname}</div>
                    <div className={"best"}>OUR BEST <span className={"destination"}>{data.destination}</span> ITINERARY</div>
                    <div className={"dates"}>{data.itinerary_dates} {data.version}</div>

                </div>

                <div className="position-absolute bottom-0 p-5">
                    <div className={"splashbtns mt-5"}>
                        <a className={"splashbtn active"} onClick={scrollToElement} href="#itinerary">START
                            ITINERARY</a>
                        <a className={"splashbtn"} onClick={scrollToElement} href="#at-glance">AT GLANCE</a>
                        {editable && <div className="dropdown">
                            <button className={`splashbtn dropdown-toggle ${showEstimates ? 'active' : ''}`}
                                    type="button"
                                    onClick={() => setShowEstimates(!showEstimates)}>
                                VIEW ESTIMATES
                            </button>
                            <ul className={`splashbtns-list ${showEstimates ? 'show' : 'hide'}`}>
                                {data.estimates.map((estimate, index) => (
                                    <li key={index}>
                                        <a href={estimate.edit_url} target={"_blank"}>{estimate.ref}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Splash;
