import React from 'react';
import {useApiData} from "../hooks/useApiData";
import scrollToElement from "../helpers/scrollToElement";

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function GlanceText() {

    const {data} = useApiData();

    const days = [];
    let dayGroup = [];
    let currLocation = null;
    for (const day of data.days) {
        if (day.location !== currLocation) {
            if (dayGroup.length > 0) {
                days.push({
                    title: currLocation,
                    days: dayGroup
                });
            }
            currLocation = day.location;
            dayGroup = [];
        }
        dayGroup.push(day);
    }
    if (dayGroup.length > 0) {
        days.push({
            title: currLocation,
            days: dayGroup
        });
    }

    const getWeekDay = day => daysOfWeek[new Date(day.unformatted_date).getDay()];

    return (
        <div className="GlanceText min-vh-100">

            <div className={"gla"}>AT GLANCE</div>

            <div className={"welcome"}>Welcome to {data.destination}</div>

            <div className={"button-bar"}>
                <a className={"button"} onClick={scrollToElement} href="#welcome-letter">WELCOME LETTER</a>
                <a className={"button"} onClick={scrollToElement} href="#gratuities">GRATUITIES</a>
                <a className={"button"} onClick={scrollToElement} href="#termsandconditions">TERMS AND CONDITIONS</a>
            </div>

            <div className={"destinationlist"}>
                {days.map((row, index) => (
                    <React.Fragment key={index}>
                        <div className={"destinationlistitem"}>
                                <div className={"where"}>{row.title}</div>
                                <div className={"bydate"} >
                                {row.days.map((day, dayindex) => (
                                    <div className={"bydateitem"} key={dayindex}>
                                        <a href={`#${day.id}`}>
                                        <div className={"when"}><span className={"day"}>DAY {day.day_number}</span> - <span className={"date"}>{getWeekDay(day)}, {day.date}</span></div>
                                        <div className={"what"}>
                                            {day.items.map((item, index) => {
                                                if(item.is_transportation || item.is_partofbundle || !item.title) {
                                                    return null;
                                                }
                                                return (
                                                    <div className={"whatitem"} key={index} dangerouslySetInnerHTML={{__html: item.title}}></div>
                                                )
                                            })}
                                        </div>
                                        </a>
                                    </div>
                                ))}
                                </div>

                            <hr/>
                        </div>
                    </React.Fragment>
                ))}
            </div>


        </div>
    );
};

export default GlanceText;
