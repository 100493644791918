import React from "react";
import {BlockEditableText} from "./BlockEditableText";

export function BlockDescription({model, className = ""}) {
    return (
        <div className={`BlockDescription ${className}`}>
            <BlockEditableText model={model}
                               attribute={model.itinerary_description ? 'itinerary_description' : 'description'}
            />
        </div>
    )
}