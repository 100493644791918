import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes,} from "react-router-dom";
import ItineraryTool from "./components/ItineraryTool";
/* STYLES */
import './App.scss';
import {DataProvider} from "./context/DataContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Message from "./components/Message";
import {onMessage} from "firebase/messaging";
import {messaging} from "./firebase/firebaseConfig";
import Notifications from "./firebase/Notifications";


function App() {

    // once we have a message from firebase. We will show a toast
    onMessage(messaging, (payload) => {
        toast(<Message notification={payload.notification} />);
    });

    return (
        <DataProvider>

            {/* Notifications will display here */}
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar
                closeOnClick
            />

            {/* Main App will display the ItineraryTool component only when a guid is present. */}
            <BrowserRouter>
                <Routes>
                    <Route path="/:guid" element={<ItineraryTool/>}/>
                </Routes>
            </BrowserRouter>
        </DataProvider>

    );
}

export default App;
