import React from "react";
import {
    BlockAlternativeText, BlockCancellationPolicy,
    BlockDescription,
    BlockImages, BlockItineraryNotes, BlockPrice, BlockRemarks, BlockRenderer,
    BlockSubtitle,
    BlockTable,
    BlockTitle
} from "../blocks/Blocks";

export const Restaurant = ({model}) => {
    return (
        <div className={"Restaurant"}>

            <BlockRenderer Component={BlockTitle} model={model}/>

            <BlockRenderer Component={BlockAlternativeText} model={model}/>

            <BlockRenderer Component={BlockSubtitle} model={model}>
                {model.provider_label && <div>{model.provider_label}</div>}
                {model.address && (<div><span>Address:</span> {model.address} </div>)}
                {model.emergency_phone && (
                    <div>
                        <span>Phone number: </span>
                        <a href={`phone: ${model.emergency_phone}`}>{model.emergency_phone}</a>
                    </div>
                )}
                {model.voucherdataarr.bookingref && <div>Reservation number: <strong
                    dangerouslySetInnerHTML={{__html: model.voucherdataarr.bookingref}}></strong></div>}

            </BlockRenderer>

            <BlockRenderer Component={BlockImages} model={model}/>

            <BlockRenderer Component={BlockTable} model={model}/>

            <BlockRenderer Component={BlockDescription} model={model}/>

            <BlockRenderer Component={BlockItineraryNotes} model={model}/>

            <BlockRenderer Component={BlockCancellationPolicy} model={model}/>

            <BlockRenderer Component={BlockPrice} model={model}/>

            <BlockRenderer Component={BlockRemarks} model={model}/>

        </div>
    )
};
