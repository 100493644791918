import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);


const swConfig = {
    // handle the update
    // In the onUpdate function, you can implement a logic to show a notification to the user, or to reload the page automatically.
    onUpdate : function(registration) {
        console.error('service worker has a newer version');
        // alert('A new version of the app is available. Refreshing the page!!!!');
        if (registration && registration.waiting) {
            // Send a message to the service worker to skip waiting
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
        window.location.reload(true);
    },
    onSuccess : function(registration) {
        console.info('service worker on success state');
    }
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(swConfig);
// serviceWorkerRegistration.unregister();



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
