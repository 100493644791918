import React from 'react';
import './Hamburger.scss';

function Hamburger({className, ...props}) {

    const mergedClassName = `Hamburger ${className || ''}`;

    return (
        <div className={mergedClassName}  {...props}>
            <div className="hamburger-line"></div>
            <div className="hamburger-line"></div>
            <div className="hamburger-line"></div>
        </div>

    );
};

export default Hamburger;
