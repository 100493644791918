import React from "react";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import './BlockRenderer.scss';
import {useApiData} from "../../hooks/useApiData";

/**
 * Get the component id from the component name removing the 'Block' prefix and converting to lowercase
 * @param component
 * @returns {string}
 */
const getComponentId = (component) => {
    return component.name.slice(5).toLowerCase();
}

/**
 * Get the component readable name from the component name removing the 'Block' prefix and splitting the name into words
 * @param component
 */
const getComponentReadableName = (component) => {
    return component.name.slice(5).split(/(?=[A-Z])/).join(' ');
}

export function BlockRenderer(props) {
    const {editable} = useApiData();
    const {Component, model, placeholder, children, className} = props;

    // The block's visibility is stored as blockid: boolean in model.view_state.blocks
    const hidden = model.view_state.blocks[getComponentId(Component)] === false;
    const [isHidden, setIsHidden] = React.useState(hidden);

    const [iconColor, setIconColor] = React.useState('black');

    if(!editable) {
        return (
            <div className={`BlockRenderer`}>
                {isHidden ? null : <Component model={model} className={className}>{children}</Component>}
            </div>
        )
    }

    const toggleVisibility = () => {
        const currentVisibility = isHidden;
        // For now, simulating an async call to the API to update the block visibility that returns after 0.5 second
        // It can randomly fail 1/10 times and reset the visibility to the previous state
        setTimeout(() => {
            setIsHidden(!isHidden);
            // Simulate a random failure
            if (Math.random() > 0.9) {
                setIsHidden(currentVisibility);
                // Setting the color of the icon to red for 1 second to give feedback
                setIconColor('error')
                setTimeout(() => {
                    setIconColor('black');
                }, 1000);
            }
        }, 500);
    }

    return (
        <div className={`BlockRenderer`}>
            <Component model={model} className={`blockrenderer-content ${isHidden ? 'hidden' : 'show'}`}>
                {children}
            </Component>
            <div className={`blockrenderer-action-bar ${isHidden ? 'hidden' : 'show'}`}>
                <div className={`blockrenderer-action blockrenderer-action-hide`}
                     onClick={() => toggleVisibility()}>
                    {isHidden ? <VisibilityOutlinedIcon fontSize={'small'} color={iconColor}/> :
                        <VisibilityOffOutlinedIcon fontSize={'small'} color={iconColor}/>}
                    <div className={`blockrenderer-placeholder ${isHidden ? 'show' : 'hidden'}`}>
                        {placeholder || getComponentReadableName(Component)}
                    </div>
                </div>
            </div>
        </div>
    )
}
