import React, {useState} from 'react';
import {useApiData} from "../hooks/useApiData";
import {ComponentBlock} from "./servicecategories/ComponentBlock";


function DayBlock({id, day}) {

    const [open, setOpen] = useState(false);
    const {data} = useApiData();

    const container = React.useRef();

    const propertytable = [
        {
            term: "HTML",
            description: "The standard markup language for documents designed to be displayed in a web browser."
        },
        {
            term: "CSS",
            description: "A style sheet language used for describing the presentation of a document written in HTML or XML."
        },
        {
            term: "CSS",
            description: "A style sheet language used for describing the presentation of a document written in HTML or XML."
        },

        // Add more items as needed
    ];

    return (
        <div id={id} ref={container} className="DayBlock colsize">
            {day.items.map((item, index) => (
                <React.Fragment key={index}>
                    <ComponentBlock item={item}></ComponentBlock>
                </React.Fragment>
            ))}


            {/*/!*<Map/>*!/*/}

            {/*/!*<MediaPlayer/>*!/*/}

            {/*/!*<SimpleSlider/>*!/*/}

        </div>
    );
};

export default DayBlock;
