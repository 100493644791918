import React from "react";
import {
    BlockPrice,
    BlockTitle,
    BlockUpsells,
    BlockAlternativeText,
    BlockDescription,
    BlockImages,
    BlockCancellationPolicy,
    BlockCombo,
    BlockRemarks,
    BlockItineraryNotes,
    BlockTable,
    BlockSubtitle,
    BlockRenderer
} from "../blocks/Blocks";

export const Hotel = ({model}) => {
    return (
        <div className={"Hotel"}>

            <BlockRenderer Component={BlockTitle} model={model}/>

            <BlockRenderer Component={BlockSubtitle} model={model}>
                {model.provider_label && <div>{model.provider_label}</div>}
                {model.address && (<div><span>Address:</span> {model.address} </div>)}
                {!model.is_proposal && model.emergency_phone && (
                    <div>
                        <span>Phone number: </span>
                        <a href={`tel:${model.emergency_phone}`}>{model.emergency_phone}</a>
                    </div>
                )}
            </BlockRenderer>

            <BlockRenderer Component={BlockAlternativeText} model={model}/>

            <BlockRenderer Component={BlockTable} model={model}/>

            <BlockRenderer Component={BlockDescription} model={model}/>

            <BlockRenderer Component={BlockImages} model={model}/>

            <BlockRenderer Component={BlockItineraryNotes} model={model}/>

            <BlockRenderer Component={BlockPrice} model={model}/>

            <BlockRenderer Component={BlockUpsells} model={model}/>

            <BlockRenderer Component={BlockCombo} model={model}/>

            <BlockRenderer Component={BlockRemarks} model={model}/>

            <BlockRenderer Component={BlockCancellationPolicy} model={model}/>


        </div>
    )
};
