import "./BlockTitle.scss";
import {Icon} from "../Icon";
import React from "react";
import {useApiData} from "../../hooks/useApiData";

export function BlockTitle({model, className = ""}) {
    const {editable} = useApiData();
    return (
        <div className={`BlockTitle ${className}`}>
            <div className={"title"} dangerouslySetInnerHTML={{__html: model.itinerary_title || model.title}}></div>
            <div className={"icon"}>
                {/*{editable &&*/}
                <a href={model.edit_url} target="_blank" rel="noreferrer">
                <Icon name={model.scat} tootltip={model.servicecategory}></Icon>
                </a>
                {/*}*/}
                {/*{!editable &&*/}
                {/*<Icon name={model.scat}></Icon>*/}
                {/*}*/}

            </div>
        </div>

    )
}
