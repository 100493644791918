import React from "react";
import {
    BlockAlternativeText,
    BlockDescription,
    BlockImages,
    BlockItineraryNotes,
    BlockPrice,
    BlockRemarks,
    BlockTable,
    BlockTitle
} from "../blocks/Blocks";

export const Guide = ({model}) => {
    return (
        <div className={"Guide"}>
            <BlockTitle model={model}/>

            <BlockAlternativeText model={model}/>

            <BlockImages model={model}/>

            <BlockDescription model={model}/>

            <BlockItineraryNotes model={model}/>

            <BlockPrice model={model}/>

            <BlockRemarks model={model}/>

            <BlockTable model={model}/>
        </div>
    )
};
