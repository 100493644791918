import React, {useEffect, useState} from 'react';
import PermissionUx from './PermissionUx'; // Import the Panel component
import {hasPermission} from "./requestToken";
import './Notifications.scss';
const Notifications = () => {

    const [isPanelVisible, setIsPanelVisible] = useState(!hasPermission());

    useEffect(() => {
        if (hasPermission()) {
            setIsPanelVisible(false);
        }
    }, []);



    const togglePanel = () => {
        setIsPanelVisible(!isPanelVisible);
    };

    return (
        <div className={'Notifications'}>
            <div className={`panelo ${isPanelVisible ? 'panelo-visible' : ''}`}>
                <PermissionUx />
            </div>
        </div>
    );
};

export default Notifications;
