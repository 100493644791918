import {BlockText} from "./BlockText";

export function BlockRemarks({model, className = ""}) {
    if(!model.remarks) {
        return <div className={`BlockRemarks ${className}`}></div>;
    }
    return (
        <div className={`BlockRemarks ${className}`}>
            <BlockText model={model} attribute="remarks"></BlockText>
        </div>
    );
}