import React from 'react';
import {Placeholder} from "rsuite";
import {useApiData} from "../hooks/useApiData";

function GlanceImage() {

    const {data} = useApiData();

    const style = {
        backgroundImage: `linear-gradient(to top, rgb(14, 36, 54) 0%, transparent 10%), url(${data.splash_image})`,
    };

    const locations = [];
    // Extracting all different locations from the days
    for (const day of data.days) {
        if (day.location && !locations.includes(day.location)) {
            locations.push(day.location);
        }
    }

    return (
        <div style={style} className={`GlanceImage fullsize`} >

            <div className={"cont"}>
                <div className={"where"}>
                    {locations.join(", ")}
                </div>
                <div className={"when"}>
                    {data.itinerary_dates}
                </div>
            </div>
        </div>
    );
};

export default GlanceImage;
