import {BlockIncluding} from "./BlockIncluding";
import {BlockProposalBundle} from "./BlockProposalBundle";

export function BlockBundle({model, className = ""}) {
    if(model.proposal) {
        return (
            <div className={`BlockBundle ${className}`}>
                <BlockProposalBundle model={model}></BlockProposalBundle>
            </div>
        );
    }

    if(model.bundlelines_count < 0) {
        return <div className={`BlockBundle ${className}`}></div>;
    }

    return (
        <div className={`BlockBundle ${className}`}>
            <BlockIncluding model={model}></BlockIncluding>
        </div>
    );
}